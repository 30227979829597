import { getAlertsByData, getPhotoIds } from '../helpers/Utils';
import {
  extractedDataDefault,
  preInspectDefault,
} from '../static/constants/default';
import { IAction, IState, Photo } from '../types';
import { Field, FieldSection } from '../types/form';
import { ActionType } from './actions';
import { MediaId } from './photoConstants';

function initialPhotoState(id: MediaId) {
  return id === MediaId.ACCESSORIES
    ? ([] as Photo[])
    : { id: id, notValidZone: false, url: undefined };
}

// Change to uppercase, remove whitespace
export function normalizePlate(plate: string): string {
  return plate
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, '')
    .replace(/\s/g, '')
    .trim();
}

/** Creates a MediaId => { id } mapping */
function initialPhotosState() {
  const photoIds = getPhotoIds();

  const state: { [key in MediaId]?: Photo | Photo[] } = {};
  (Object.keys(MediaId) as MediaId[])
    .filter((media) => photoIds.includes(media))
    .forEach((mediaId) => {
      state[mediaId] = initialPhotoState(mediaId);
    });

  return state as { [key in MediaId]: Photo | Photo[] };
}

export const initialState: IState = {
  preInspectionId: null,
  preInspectionModel: preInspectDefault,
  photos: initialPhotosState(),
  formData: null,
  formDataInit: null,
  extractedData: extractedDataDefault, // data extracted from images
  alertData: null,
  location: null,
  finishStatus: null,
  externalCarImagesIsBlurry: null,
  refreshGalleryOneScreen: null,
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.SET_PREINSPECTION_ID:
      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preInspectionId: action.payload,
        },
      };
    case ActionType.SET_PREINSPECTION_UNIQUEID:
      return { ...state, preInspectionId: action.payload };
    case ActionType.SET_PREINSPECTION_MODEL:
      return { ...state, preInspectionModel: action.payload };
    case ActionType.SET_PREINSPECTION_FORM_INIT:
      return { ...state, formDataInit: action.payload };
    case ActionType.SET_PREINSPECTION_FORM:
      return { ...state, formData: action.payload };
    case ActionType.SET_PREINSPECTION_MEDIA:
      return { ...state, photos: action.payload };
    case ActionType.SET_FINISH_STATUS:
      return { ...state, finishStatus: action.payload };
    case ActionType.SET_PREINSPECTION_GEOLOCATION:
      return {
        ...state,
        location: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };
    case ActionType.ADD_PHOTO: {
      const photos = { ...state.photos };
      const id = action.payload.id as MediaId;

      if (Array.isArray(photos[id])) {
        const accessoryPhotos = photos[id] as Photo[];

        const lastAccessoryPhoto = accessoryPhotos[accessoryPhotos.length - 1];

        if (
          lastAccessoryPhoto &&
          (lastAccessoryPhoto.uniqueId === action.payload.uniqueId ||
            (lastAccessoryPhoto.attemps !== undefined &&
              lastAccessoryPhoto.attemps !== 0 &&
              !lastAccessoryPhoto.url))
        ) {
          return state;
        }

        accessoryPhotos.push({
          id,
          url: action.payload.url,
          uniqueId: action.payload.uniqueId,
        });
      } else {
        photos[id] = {
          ...photos[id],
          url: action.payload.url,
        };
      }

      return { ...state, photos };
    }
    case ActionType.UPDATE_PHOTO_URL: {
      const photos = { ...state.photos };
      const id = action.payload.id as MediaId;

      if (Array.isArray(photos[id])) {
        const updatedAccessoryPhotos = (photos[id] as Photo[]).map((item) => {
          if (item.uniqueId === action.payload.uniqueId) {
            return {
              ...item,
              url: action.payload.url,
            };
          }

          return item;
        });

        photos[id] = updatedAccessoryPhotos;
      } else {
        photos[id] = {
          ...photos[id],
          url: action.payload.url,
        };
      }

      return { ...state, photos };
    }

    case ActionType.REMOVE_PHOTO: {
      const photos = { ...state.photos };
      const id = action.payload.id as MediaId;

      if (Array.isArray(photos[id])) {
        const updatedAccessoryPhotos = (photos[id] as Photo[]).filter(
          (item) => item.uniqueId !== action.payload.uniqueId,
        );
        photos[id] = updatedAccessoryPhotos;
      } else {
        const photoInitState = initialPhotoState(id);
        const oldPhotoConfig = photos[id] as Photo;
        photos[id] = {
          ...photoInitState,
          attemps: oldPhotoConfig.attemps,
          notValid: oldPhotoConfig.notValid,
          notValidZone: oldPhotoConfig.notValidZone,
        };
      }

      return { ...state, photos };
    }

    case ActionType.ATTEMPT_PHOTO: {
      const photos = { ...state.photos };
      const id = action.payload.id as MediaId;

      if (Array.isArray(photos[id])) {
        const updatedAccessoryPhotos = (photos[id] as Photo[]).map((item) => {
          if (item.uniqueId === action.payload.uniqueId) {
            return {
              ...item,
              attemps: action.payload.attemps,
              isBlurry: action.payload.isBlurry ?? item.isBlurry,
            };
          }

          return item;
        });

        photos[id] = updatedAccessoryPhotos;
      } else {
        photos[id] = {
          ...photos[id],
          attemps: action.payload.attemps,
          isBlurry: action.payload.isBlurry ?? (photos[id] as Photo).isBlurry,
        };
      }

      return { ...state, photos };
    }

    case ActionType.NOTVALID_PHOTO: {
      const photos = { ...state.photos };
      const id = action.payload.id as MediaId;

      if (Array.isArray(photos[id])) {
        const updatedAccessoryPhotos = (photos[id] as Photo[]).map((item) => {
          if (item.uniqueId === action.payload.uniqueId) {
            return {
              ...item,
              notValid: action.payload.notValid,
            };
          }

          return item;
        });

        photos[id] = updatedAccessoryPhotos;
      } else {
        photos[id] = {
          ...photos[id],
          notValid: action.payload.notValid,
        };
      }

      return { ...state, photos };
    }

    case ActionType.SET_EXTRACTED_PLATE: {
      const plate = normalizePlate(action.payload);

      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preVehiclePlate: plate,
        },
        extractedData: { ...state.extractedData, plate: plate },
      };
    }
    case ActionType.SET_EXTRACTED_VIN:
      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preVehicleVin: action.payload,
        },
        extractedData: { ...state.extractedData, vin: action.payload },
      };
    case ActionType.SET_EXTRACTED_RUV: {
      let vehicleData = action.payload;

      if (!state.formData) {
        return {
          ...state,
        };
      }

      const { formData, alertData } = getAlertsByData(
        vehicleData,
        state.preInspectionModel,
        state.alertData,
        state.formData,
      );

      // Set Owner attributes
      vehicleData = {
        ...action.payload,
        name: '',
        lastName1: '',
        lastName2: '',
      };

      // Define the current Owner Name for the last analysis of the process
      state.formData.forEach((section: FieldSection) => {
        if (
          section.level === '2' &&
          section.name !== undefined &&
          ['PRE_GENERAL'].includes(section.name)
        ) {
          section.fields.forEach((field: Field) => {
            if (
              vehicleData !== null &&
              vehicleData !== undefined &&
              ['preName', 'preLastName1', 'preLastName2'].includes(field.idName)
            ) {
              switch (field.idName) {
                case 'preName':
                  vehicleData.name = field.fieldFormValue;
                  break;
                case 'preLastName1':
                  vehicleData.lastName1 = field.fieldFormValue;
                  break;
                case 'preLastName2':
                  vehicleData.lastName2 = field.fieldFormValue;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });

      return {
        ...state,
        formData: formData,
        extractedData: vehicleData,
        alertData: alertData,
      };
    }
    case ActionType.SET_ALERT_MODEL:
      return { ...state, alertData: action.payload };

    case ActionType.SET_VEHICLE_SIDE_VALIDATION: {
      const photos = { ...state.photos };
      const notValidZone = action.payload.result;
      const currentState = photos[action.payload.id as MediaId] as Photo;
      let currentAttempt = currentState.attemps ? currentState.attemps : 0;

      currentAttempt = notValidZone ? currentAttempt + 1 : currentAttempt;

      photos[action.payload.id as MediaId] = {
        ...currentState,
        notValidZone: notValidZone,
        notValid:
          notValidZone === false
            ? false
            : currentAttempt > 2
            ? true
            : currentState.notValid,
        attemps: currentAttempt,
      };

      return { ...state, photos };
    }

    case ActionType.SET_IS_BROKER: {
      localStorage.setItem('preIsBroker', action.payload);

      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preIsBroker: action.payload ?? false,
        },
      };
    }

    case ActionType.SET_EXTERNAL_CAR_IMAGES_IS_BLURRY: {
      localStorage.setItem('externalCarImagesIsBlurry', action.payload);

      return {
        ...state,
        externalCarImagesIsBlurry: action.payload ?? false,
      };
    }

    case ActionType.SET_REFRESH_GALLERY_ONE_SCREEN: {
      return {
        ...state,
        refreshGalleryOneScreen: action.payload ?? false,
      };
    }
  }
};

export default reducer;

import React from 'react';

import {
  PhotoId as IVPhotoId,
  getTooltipContents,
} from '@connect-technology/connect-react-components-lib';
import { MediaId } from '../context/photoConstants';
import { DispatchFunction } from '../types';
import { deletePhotoFromServer } from './photoUploader';
import { ActionType } from '../context/actions';
import { Company } from '../interfaces/brand';
import { BRAND } from '../static/constants/brands/brand';

// TODO: Import from connect-react-components-lib.
interface TooltipContent {
  title: string;
  imgUrl?: string;
  paragraph: React.ReactNode;
}

/**
 * The idea of this function is allow us to both re-use the original
 * getTooltipContents from IV, and to extend it with new Photos
 * (like VEHICLE_TOP).
 */
export function getHelpModalContents(
  mediaId: MediaId | null,
): TooltipContent | null {
  if (mediaId === null) {
    return null;
  }

  const ivPhotoId = transformPhotoId(mediaId);
  if (ivPhotoId === null) {
    return null;
  }

  return getTooltipContents(ivPhotoId);
}
//
export function transformPhotoId(mediaId: MediaId): IVPhotoId | null {
  switch (mediaId) {
    // Vehicle Exterior
    case MediaId.VEHICLE_EXTERIOR_BACK:
      return IVPhotoId.INJURED_VEHICLE_BACK;
    case MediaId.VEHICLE_EXTERIOR_FRONT:
      return IVPhotoId.INJURED_VEHICLE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_LEFT:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_RIGHT:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_PLATE:
      return IVPhotoId.INJURED_VEHICLE_PLATE_PA;
    case MediaId.VEHICLE_EXTERIOR_LEFT_FRONT:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_LEFT_BACK:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE_BACK;
    case MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_RIGHT_BACK:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE_BACK;
    // Vehicle Interior
    case MediaId.VEHICLE_INTERIOR_VIN:
      return IVPhotoId.INJURED_VEHICLE_VIN;
    case MediaId.VEHICLE_INTERIOR_MOTOR:
      return IVPhotoId.INJURED_VEHICLE_MOTOR;
    case MediaId.VEHICLE_INTERIOR_1:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_1;
    case MediaId.VEHICLE_INTERIOR_2:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_2;
    case MediaId.VEHICLE_INTERIOR_ODOMETER:
      return IVPhotoId.INJURED_VEHICLE_ODOMETER;
    // Documents
    case MediaId.DOCUMENTS_CEDULA:
      return IVPhotoId.COUNTERPART_CEDULA;
    case MediaId.COUNTERPART_CEDULA:
      return IVPhotoId.COUNTERPART_CEDULA;
    case MediaId.DOCUMENTS_DRIVERS_LICENSE:
      return IVPhotoId.COUNTERPART_LICENSE_PA;
    case MediaId.VEHICLE_INTERIOR_MOTOR_PANO:
      return IVPhotoId.VEHICLE_INTERIOR_MOTOR_PANO;
    case MediaId.DOCUMENTS_DRIVERS_PORTRAIT:
      return IVPhotoId.PORTRAIT;
    case MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY:
      return IVPhotoId.COUNTERPART_REGISTRO_UNICO;
    case MediaId.DEKRA:
      return IVPhotoId.DEKRA;
    case MediaId.TRUNK:
      return IVPhotoId.TRUNK;
    case MediaId.DOCUMENTS_COUNTERPART_CEDULA_CR:
      return IVPhotoId.COUNTERPART_CEDULA_CR;
    case MediaId.DOCUMENTS_BACK_CEDULA_CR:
      return IVPhotoId.BACK_CEDULA_CR;
    default:
      return null;
  }
}

export const removePhotoFromPreview = async (
  id: MediaId,
  dispatch: DispatchFunction,
  url?: string,
): Promise<void> => {
  if (url) {
    await deletePhotoFromServer(url);
    dispatch({
      type: ActionType.REMOVE_PHOTO,
      payload: { id: id },
    });
  }
};

export const refreshGalleryOneScreen = (dispatch: DispatchFunction): void => {
  if ([Company.SURA.toString()].includes(BRAND)) {
    dispatch({
      type: ActionType.SET_REFRESH_GALLERY_ONE_SCREEN,
      payload: true,
    });
  }
};
